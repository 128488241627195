<template>
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="keywords" content="대량문자 SMS 보내기 LMS MMS 카카오 알림톡API 친구톡 이메일 팩스 국제문자" />
    <meta property="og:locale" content="ko_KR" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스" />
    <meta property="og:description" content="SK(주) C&C에서 만든 대량 문자 발송 솔루션 Message To-Go에서 쉽고 빠르게 대량 문자 발송과 단체 문자 보내기를 경험하세요. SMS, LMS, 알림톡 API 통합을 통해 기업 문자 서비스를 빠르고 효과적이게 이용하세요." />
    <meta property="og:url" content="https://message.to-go.io/home" />
    <meta property="og:site_name" content="SK(주) C&C에서 만든 대량 문자 발송 솔루션 Message To-Go에서 쉽고 빠르게 대량 문자 발송과 단체 문자 보내기를 경험하세요. SMS, LMS, 알림톡 API 통합을 통해 기업 문자 서비스를 빠르고 효과적이게 이용하세요." />
    <title>SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스</title>
    <link rel="icon" href="https://to-go.io/sk_logo.jpg" type="image/jpg">
    <!-- <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script> -->
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css"> -->
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-svg.css"> -->
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <!-- <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"> -->
    <!-- <link rel="stylesheet" href="https://message.to-go.io/assets/css/theme.css"> -->
    <!-- <link rel="stylesheet" href="./assets/css/loopple/loopple.css"> -->
</head>
  
  <body class="g-sidenav-show">
      <div class="container position-sticky z-index-sticky top-0">
          <div class="row">
              <div class="col-12">
                  <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                    <div class="container-fluid">
                          <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                          <a class="navbar-brand ms-sm-3" href="https://to-go.io">
                            <!-- <img src="../assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                            <img src="../assets/img/SK_logo.svg" alt="SK C&C Logo" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                            <!-- <span class="font-weight-bolder"> Message To-Go</span>                           -->
                          </a>
                          <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                              <span class="navbar-toggler-icon mt-2">
                                  <span class="navbar-toggler-bar bar1"></span>
                                  <span class="navbar-toggler-bar bar2"></span>
                                  <span class="navbar-toggler-bar bar3"></span>
                              </span>
                          </button>
                          <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
                              <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="#channels" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="#features" aria-expanded="false">
                                        <span class="font-weight-bolder" style="font-size: larger;">기능</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                      <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="#product" aria-expanded="false">    
                                          <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
                                      </a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home/contactus" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
                                    </a>
                                  </li>  
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home/news" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">NEWS/소식</span>
                                    </a>
                                  </li>                                  
                                  <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                                    <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" :to="`/authentication/signin/basic`">
                                      회원가입/로그인
                                    </router-link>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </nav>
              </div>
          </div>
      </div>
      <header id="top" class="">
          <div class="page-header min-vh-100">
              <div class="oblique position-absolute top-0 h-100 d-md-block d-none">
                <div class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style="background-image:url('https://demos.creative-tim.com/soft-ui-design-system/assets/img/curved-images/curved11.jpg')" alt="Background Theme Image"></div>
                  <!-- <div class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6">
                    <img src="../assets/img/m2g_main_banner.png" style="margin-top: 30px; width: 100%; height: auto;">
                  </div> -->
              </div>            
              <div class="container">
                  <div class="row">
                      <div class="col-lg-6 col-md-7 d-flex justify-content-center flex-column">
                          <!-- <h1 class="text-gradient text-primary">Message To-Go</h1> -->
                          <img src="../assets/img/logo-m2g-dark-no_url.png" alt="Message To-Go Logo" class="img-fluid mb-5" style="width: 80%; margin-left: 0%;">
                          <h1 class="mb-4"> <span class="text-gradient text-primary mb-0">SK(주) C&C에서 만든 <br>대량문자발송 솔루션!<br></span>
                            <p style="font-size: 20px; line-height: 1.5 !important; margin-top: 20px; font-weight: bold;">문자전송, 알림톡전송, 이메일전송..<br>이제 더 빠르고, 효과적으로,<br>메시지투고와 함께하세요.</p>
                          </h1>
                          <div class="buttons">
                            <a type="button" class="btn bg-gradient-primary mt-4" href="https://message.to-go.io/authentication/signup/auth">회원가입</a>
                            <a type="button" class="btn text-primary mt-4 mx-2" href="https://message.to-go.io/home/contactus">도입문의</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </header>
      <section id="channels" class="py-5">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-lg-6">
                      <div class="row justify-content-start">
                          <div class="col-md-6">
                              <div class="info">
                                  <div class="icon icon-md">
                                      <svg width="50px" height="50px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                          <title>apppush</title>
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <g transform="translate(-1720.000000, -592.000000)" fill="#923DFA" fill-rule="nonzero">
                                                  <g transform="translate(1716.000000, 291.000000)">
                                                      <g transform="translate(4.000000, 301.000000)">
                                                          <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                                                      </g>
                                                  </g>
                                              </g>
                                          </g>
                                      </svg>
                                  </div>
                                  <h2 class="h5">앱푸시</h2>
                                  <p>따로 구현하지 마시고 저희 API로 앱푸시, Slack, Teams 등 다양한 푸시를 날려보세요.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="info">
                                  <div class="icon icon-md">
                                      <svg width="50px" height="50px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                          <title>message</title>
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <g transform="translate(-1720.000000, -592.000000)" fill="#923DFA" fill-rule="nonzero">
                                                  <g transform="translate(1716.000000, 291.000000)">
                                                      <g transform="translate(4.000000, 301.000000)">
                                                          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                                      </g>
                                                  </g>
                                              </g>
                                          </g>
                                      </svg>
                                  </div>
                                  <h2 class="h5">SMS, LMS &amp; MMS</h2>
                                  <p>저렴한 비용을 다양한 형태의 문자전송.. 대량문자도 이제 더 빠르고, 효과적으로 간편하게 전송하세요.</p>
                              </div>
                          </div>
                      </div>
                      <div class="row justify-content-start">
                          <div class="col-md-6">
                              <div class="info">
                                  <div class="icon icon-md">
                                      <svg width="50px" height="50px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                          <title>kakao</title>
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <g transform="translate(-1720.000000, -592.000000)" fill="#923DFA" fill-rule="nonzero">
                                                  <g transform="translate(1716.000000, 291.000000)">
                                                      <g transform="translate(4.000000, 301.000000)">
                                                          <path fill="none" d="M0 0h24v24H0z"/> <path d="M12 3c5.799 0 10.5 3.664 10.5 8.185 0 4.52-4.701 8.184-10.5 8.184a13.5 13.5 0 0 1-1.727-.11l-4.408 2.883c-.501.265-.678.236-.472-.413l.892-3.678c-2.88-1.46-4.785-3.99-4.785-6.866C1.5 6.665 6.201 3 12 3zm5.907 8.06l1.47-1.424a.472.472 0 0 0-.656-.678l-1.928 1.866V9.282a.472.472 0 0 0-.944 0v2.557a.471.471 0 0 0 0 .222V13.5a.472.472 0 0 0 .944 0v-1.363l.427-.413 1.428 2.033a.472.472 0 1 0 .773-.543l-1.514-2.155zm-2.958 1.924h-1.46V9.297a.472.472 0 0 0-.943 0v4.159c0 .26.21.472.471.472h1.932a.472.472 0 1 0 0-.944zm-5.857-1.092l.696-1.707.638 1.707H9.092zm2.523.488l.002-.016a.469.469 0 0 0-.127-.32l-1.046-2.8a.69.69 0 0 0-.627-.474.696.696 0 0 0-.653.447l-1.661 4.075a.472.472 0 0 0 .874.357l.33-.813h2.07l.299.8a.472.472 0 1 0 .884-.33l-.345-.926zM8.293 9.302a.472.472 0 0 0-.471-.472H4.577a.472.472 0 1 0 0 .944h1.16v3.736a.472.472 0 0 0 .944 0V9.774h1.14c.261 0 .472-.212.472-.472z"/>
                                                      </g>
                                                  </g>
                                              </g>
                                          </g>
                                      </svg>
                                      
                                  </div>
                                  <h2 class="h5">카카오 알림톡</h2>
                                  <p>문자전송 전에 단가가 더욱 저렴한&nbsp; 카카오 알림톡전송으로 우선 보내보는건 어떤가요?<br>알림톡 대량전송도 척척!</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="info">
                                  <div class="icon icon-md">
                                      <svg width="50px" height="50px" viewBox="0 0 42 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                          <title>email</title>
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <g transform="translate(-2319.000000, -440.000000)" fill="#923DFA" fill-rule="nonzero">
                                                  <g transform="translate(1716.000000, 291.000000)">
                                                      <g id="time-alarm" transform="translate(603.000000, 149.000000)">
                                                          <defs><clipPath id="id1"><path d="M 3.460938 6.5625 L 26.539062 6.5625 L 26.539062 24.707031 L 3.460938 24.707031 Z M 3.460938 6.5625 " clip-rule="nonzero"/></clipPath></defs>
                                                          <g clip-path="url(#id1)"><path fill="923DFA" d="M 24.230469 11.101562 L 15 16.769531 L 5.769531 11.101562 L 5.769531 8.832031 L 15 14.503906 L 24.230469 8.832031 Z M 24.230469 6.5625 L 5.769531 6.5625 C 4.492188 6.5625 3.472656 7.578125 3.472656 8.832031 L 3.460938 22.441406 C 3.460938 23.695312 4.492188 24.707031 5.769531 24.707031 L 24.230469 24.707031 C 25.507812 24.707031 26.539062 23.695312 26.539062 22.441406 L 26.539062 8.832031 C 26.539062 7.578125 25.507812 6.5625 24.230469 6.5625 " fill-opacity="1" fill-rule="nonzero"/></g>
                                                      </g>
                                                  </g>
                                              </g>
                                          </g>
                                      </svg>
                              
                                  </div>
                                  <h2 class="h5">이메일 & 팩스</h2>
                                  <p>이메일전송을 해야 하나요? 팩스발송이 필요한가요? 메시지투고와 함께하세요.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-5 ms-auto">
                      <div class="card">
                        <img class="card-img-top" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/funny.jpg" alt="Funny Image">
                          <!-- <img class="card-img-top" src="../assets/img/characters_sharing.png"> -->
                          <div class="position-relative overflow-hidden" style="height:50px;margin-top:-50px;">
                              <div class="position-absolute w-100 top-0 z-index-1">
                                  <svg class="waves waves-sm" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                                      <defs>
                                          <path id="card-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                                      </defs>
                                      <g class="moving-waves">
                                          <use xlink:href="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30"></use>
                                          <use xlink:href="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                          <use xlink:href="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                          <use xlink:href="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                          <use xlink:href="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                          <use xlink:href="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)"></use>
                                      </g>
                                  </svg>
                              </div>
                          </div>
                          <div class="card-body">
                              <h2 class="h4">One Stop 통합 메시징 솔루션</h2>
                              <p>간단한 연동으로 모든 메시지 발송을 한번에, 더 편리하게 <br>사용하세요. SMS, LMS, MMS, 카카오톡, 이메일, 팩스를 대량발송부터 이력까지 통합 관리 가능합니다.</p>
                              <a href="/authentication/signup/auth" class="text-primary icon-move-right">가입 후 체험하기&nbsp;<i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="py-5">
          <div class="container py-5">
              <div class="row align-items-center">
                  <div class="col-md-6 mb-md-0 mb-4">
                      <h2 class="text-gradient text-primary mb-0 mb-2 h3">지금보다 매달 20%이상 저렴하게 문자발송!</h2>
                      <p class="lead mb-md-5 mb-4">예상외의 높은 메시지전송 비용.<br> 간단한 전환으로 꾸준한 절감효과를 누릴 수 있습니다.</p>
                      <p><span class="me-2">●</span>&nbsp;앱푸시를 자체 구축하려니 비용이 걱정이신가요?</p>
                      <p><span class="me-2">●</span>&nbsp;메시지솔루션을 도입하는데 인프라를 따로 구축이 고민이신가요?</p>
                      <p><span class="me-2">●</span> 대량문자를 발송해야 하는데 비용이 걱정되시나요?</p>
                      <p><span class="me-2">●</span> 기존솔루션을 대체하려는데 작업할 개발자가 부족하신가요?<br><br></p>
                      <p class="text-gradient text-primary mb-0 mb-2 h4">메시지투고가 해결해 드립니다.</p>
                  </div>
                  <div class="col-md-6">
                      <div class="blur-shadow-image text-center">
                          <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/curved-images/curved5.jpg" alt="img-blur-shadow" class="img-fluid shadow border-radius-lg max-height-600">
                          <!-- <img src="../assets/img/sending_message.png" alt="img-blur-shadow" class="img-fluid shadow border-radius-lg max-height-600"> -->
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="py-5">
          <div class="container-fluid px-0">
              <div class="row justify-content-center text-center">
                  <div class="col-3 px-md-0"></div>
                  <div class="col-12 position-relative">
                      <div class="row">
                          <div class="col-xl-3"></div>
                          <div class="col-xl-5 col-md-8 col-12 text-start">
                              <div class="p-3 text-start border-radius-lg">
                                  <p class="text-gradient text-primary mb-0 mb-2 h2">심플하게 빠르다</p>
                                  <p class="text-dark text-lg mt-3">
                                      <span class="font-weight-bold">메시지투고</span>
                                      는 복잡도는 낮추고 접근성은 높였습니다.<br>
                                      웹문자발송, API문자발송 및 DB Agent 문자발송 모두 쉽고 빠르게 적용 가능합니다.</p>
                              </div>
                          </div>
                      </div>
                      <div class="row bg-warning-soft bottom-0">
                          <div class="row">
                              <div class="col-xl-3 position-relative">
                                  <img class="w-100 border-radius-section border-top-start-radius-0 border-bottom-start-radius-0 start-0 position-absolute max-width-300 mt-n11 d-xl-block d-none" src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/curved-images/curved13.jpg" alt="image">
                              </div>
                              <div class="col-xl-9">
                                  <div class="row">
                                      <div class="col-xl-3 col-md-4 py-md-5 py-3">
                                          <div class="p-3 text-start border-radius-lg">
                                              <div class="icon icon-shape icon-md bg-gradient-warning shadow text-center">
                                                  <svg width="30px" height="18px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mt-3">
                                                      <title>credit-card</title>
                                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                          <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                              <g transform="translate(1716.000000, 291.000000)">
                                                                  <g id="credit-card" transform="translate(453.000000, 454.000000)">
                                                                      <path d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
                                                                      <path d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                                                                  </g>
                                                              </g>
                                                          </g>
                                                      </g>
                                                  </svg>
                                              </div>
                                              <p class="mt-3 h5">Low Cost</p>
                                              <p>메시지 채널별로 최저가를 경험해 보세요. <br>웹, API, DB Agent 모두!</p>
                                          </div>
                                      </div>
                                      <div class="col-xl-3 col-md-4 py-md-5 py-3">
                                        <div class="p-3 text-start border-radius-lg">
                                              <div class="icon icon-shape icon-md bg-gradient-warning shadow text-center">
                                                  <svg width="30px" height="18px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mt-3">
                                                      <title>spaceship</title>
                                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                          <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                              <g transform="translate(1716.000000, 291.000000)">
                                                                  <g transform="translate(4.000000, 301.000000)">
                                                                      <path d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                                                      <path d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                                                      <path d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" opacity="0.598539807"></path>
                                                                      <path id="color-3" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" opacity="0.598539807"></path>
                                                                  </g>
                                                              </g>
                                                          </g>
                                                      </g>
                                                  </svg>
                                              </div>
                                              <p class="mt-3 h5">Fast</p>
                                              <p>대량문자도 빠르게. <br>전송하고 잊으세요.</p>
                                          </div>
                                      </div>
                                      <div class="col-xl-3 col-md-4 py-md-5 py-3">
                                          <div class="p-3 text-start border-radius-lg">
                                              <div class="icon icon-shape icon-md bg-gradient-warning shadow text-center">
                                                  <svg width="30px" height="18px" viewBox="0 0 42 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mt-3">
                                                      <title>basket</title>
                                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                          <g transform="translate(-1869.000000, -741.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                              <g transform="translate(1716.000000, 291.000000)">
                                                                  <g id="basket" transform="translate(153.000000, 450.000000)">
                                                                      <path d="M34.080375,13.125 L27.3748125,1.9490625 C27.1377583,1.53795093 26.6972449,1.28682264 26.222716,1.29218729 C25.748187,1.29772591 25.3135593,1.55890827 25.0860125,1.97535742 C24.8584658,2.39180657 24.8734447,2.89865282 25.1251875,3.3009375 L31.019625,13.125 L10.980375,13.125 L16.8748125,3.3009375 C17.1265553,2.89865282 17.1415342,2.39180657 16.9139875,1.97535742 C16.6864407,1.55890827 16.251813,1.29772591 15.777284,1.29218729 C15.3027551,1.28682264 14.8622417,1.53795093 14.6251875,1.9490625 L7.919625,13.125 L0,13.125 L0,18.375 L42,18.375 L42,13.125 L34.080375,13.125 Z" opacity="0.595377604"></path>
                                                                      <path d="M3.9375,21 L3.9375,38.0625 C3.9375,40.9619949 6.28800506,43.3125 9.1875,43.3125 L32.8125,43.3125 C35.7119949,43.3125 38.0625,40.9619949 38.0625,38.0625 L38.0625,21 L3.9375,21 Z M14.4375,36.75 L11.8125,36.75 L11.8125,26.25 L14.4375,26.25 L14.4375,36.75 Z M22.3125,36.75 L19.6875,36.75 L19.6875,26.25 L22.3125,26.25 L22.3125,36.75 Z M30.1875,36.75 L27.5625,36.75 L27.5625,26.25 L30.1875,26.25 L30.1875,36.75 Z"></path>
                                                                  </g>
                                                              </g>
                                                          </g>
                                                      </g>
                                                  </svg>
                                              </div>
                                              <p class="mt-3 h5">Unified</p>
                                              <p>한곳에서 모든 메시지관리<br>모두 한번에!</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-1"></div>
                          </div>
                          <!-- <img class="w-10 end-10 position-absolute mt-n6" src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/pattern-points.png" alt="image"> -->
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section  id="features" class="bg-gradient-primary position-relative overflow-hidden">
          <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/shapes/waves-white.svg" alt="pattern-lines" class="position-absolute opacity-6">
          <div class="position-absolute w-100 z-inde-1 top-0 mt-n3">
              <svg width="100%" viewBox="0 -2 1920 157" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>wave-down</title>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g fill-rule="nonzero">
                          <g id="wave-down">
                              <path id="Path-Copy-2" d="M0,60.8320331 C299.333333,115.127115 618.333333,111.165365 959,47.8320321 C1299.66667,-15.5013009 1620.66667,-15.2062179 1920,47.8320331 L1920,156.389409 L0,156.389409 L0,60.8320331 Z" transform="translate(960.000000, 78.416017) rotate(180.000000) translate(-960.000000, -78.416017) "></path>
                          </g>
                      </g>
                  </g>
              </svg>
          </div>
          <div class="container pt-6 pb-7">
              <div class="row align-items-center">
                  <div class="col-lg-6">
                      <div class="row justify-content-start">
                          <div class="col-md-6">
                              <div class="info">
                                  <div class="icon icon-md">
                                      <svg width="70px" height="70px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                          <title>spaceship</title>
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                  <g transform="translate(1716.000000, 291.000000)">
                                                      <g transform="translate(4.000000, 301.000000)">
                                                          <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/> 
                                                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                      </g>
                                                  </g>
                                              </g>
                                          </g>
                                      </svg>
                                  </div>
                                  
                                  <h3 class="text-white h5">예약문자전송</h3>
                                  <p class="text-white opacity-8">예약해 놓고 잊으세요. 저희가 시간 맞춰 전송합니다.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="info">
                                <div class="icon icon-md">
                                  <svg width="50px" height="50px" viewBox="0 -3 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <title>kakao</title>
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                            <g transform="translate(1716.000000, 291.000000)">
                                                <g transform="translate(4.000000, 301.000000)">
                                                    <path fill="none" d="M0 0h24v24H0z"/>
                                                    <path d="m24.436 12.56v.051c0 .482-.035.956-.102 1.42l.006-.053h-15.695c0 .022-.001.049-.001.075 0 1.3.577 2.466 1.488 3.255l.005.005c.902.833 2.112 1.344 3.442 1.344.024 0 .049 0 .073-.001h-.004.025c.918 0 1.781-.235 2.532-.648l-.027.014c.78-.419 1.411-1.025 1.85-1.76l.012-.022h5.768c-.528 1.489-1.32 2.767-2.33 3.837l.005-.005c-2.011 2.143-4.86 3.478-8.022 3.478-1.769 0-3.441-.418-4.921-1.161l.063.029c-1.546.894-3.381 1.467-5.339 1.581l-.033.002q-3.232 0-3.232-3.586c.039-1.351.26-2.636.641-3.85l-.027.101c.454-1.208.954-2.236 1.536-3.21l-.05.09c1.748-3.199 3.909-5.929 6.454-8.241l.026-.023q-2.509 1.078-5.822 4.827c.564-2.505 1.945-4.623 3.842-6.112l.023-.018c1.822-1.49 4.175-2.393 6.739-2.393h.105-.005q.409 0 .614.014c1.715-.898 3.728-1.474 5.863-1.597l.039-.002h.068c.537 0 1.059.065 1.559.187l-.045-.009c.489.121.919.311 1.305.562l-.016-.01c.391.258.7.61.901 1.025l.007.015c.207.436.328.946.328 1.486 0 .029 0 .058-.001.087v-.004c-.081 1.434-.45 2.764-1.049 3.958l.026-.058c.866 1.518 1.376 3.336 1.376 5.272v.053-.003zm-.954-8.728c.002-.034.003-.073.003-.112 0-.663-.277-1.262-.722-1.687l-.001-.001c-.465-.416-1.081-.67-1.758-.67-.039 0-.077.001-.116.002h.005c-1.277.065-2.461.411-3.508.976l.044-.022c1.164.459 2.167 1.064 3.051 1.806l-.017-.014c.907.756 1.678 1.636 2.298 2.62l.027.046c.378-.864.626-1.867.692-2.919l.001-.025zm-21.736 16.868c-.003.046-.005.099-.005.153 0 .643.254 1.226.667 1.655l-.001-.001c.446.397 1.037.639 1.685.639.053 0 .105-.002.157-.005h-.007c1.351-.077 2.592-.488 3.66-1.151l-.033.019c-1.133-.677-2.095-1.507-2.894-2.477l-.015-.019c-.79-.948-1.427-2.057-1.852-3.266l-.023-.075c-.727 1.309-1.208 2.849-1.337 4.488l-.002.039zm6.872-9.722h9.927c-.027-1.296-.617-2.448-1.535-3.227l-.006-.005c-.908-.804-2.11-1.295-3.426-1.295s-2.518.491-3.431 1.3l.005-.005c-.92.785-1.508 1.938-1.534 3.227z"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                  </svg>
                                  </div>
                                  <!-- <span class="font-weight-bolder text-white p-2 border rounded">https://to-go.io/3qGAoL9</span>  -->
                                  <h3 class="text-white h5">접근고객 세분화</h3>
                                  <p class="text-white opacity-8">문자에 고객별 고유 Short 링크를 자동 생성해 누가 링크를 눌렀는지 확인.</p>
                              </div>
                          </div>
                      </div>
                      <div class="row justify-content-start">
                          <div class="col-md-6">
                              <div class="info">
                                  <div class="icons-row">
                                    <div class="icon icon-md">
                                        <svg width="50px" height="50px" viewBox="0 -3 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <title>kakao</title>
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                    <g transform="translate(1716.000000, 291.000000)">
                                                        <g transform="translate(4.000000, 301.000000)">
                                                            <path fill="none" d="M0 0h24v24H0z"/> <path d="M12 3c5.799 0 10.5 3.664 10.5 8.185 0 4.52-4.701 8.184-10.5 8.184a13.5 13.5 0 0 1-1.727-.11l-4.408 2.883c-.501.265-.678.236-.472-.413l.892-3.678c-2.88-1.46-4.785-3.99-4.785-6.866C1.5 6.665 6.201 3 12 3zm5.907 8.06l1.47-1.424a.472.472 0 0 0-.656-.678l-1.928 1.866V9.282a.472.472 0 0 0-.944 0v2.557a.471.471 0 0 0 0 .222V13.5a.472.472 0 0 0 .944 0v-1.363l.427-.413 1.428 2.033a.472.472 0 1 0 .773-.543l-1.514-2.155zm-2.958 1.924h-1.46V9.297a.472.472 0 0 0-.943 0v4.159c0 .26.21.472.471.472h1.932a.472.472 0 1 0 0-.944zm-5.857-1.092l.696-1.707.638 1.707H9.092zm2.523.488l.002-.016a.469.469 0 0 0-.127-.32l-1.046-2.8a.69.69 0 0 0-.627-.474.696.696 0 0 0-.653.447l-1.661 4.075a.472.472 0 0 0 .874.357l.33-.813h2.07l.299.8a.472.472 0 1 0 .884-.33l-.345-.926zM8.293 9.302a.472.472 0 0 0-.471-.472H4.577a.472.472 0 1 0 0 .944h1.16v3.736a.472.472 0 0 0 .944 0V9.774h1.14c.261 0 .472-.212.472-.472z"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="icon icon-md">
                                        <svg width="50px" height="50px" viewBox="0 -5 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <title>message</title>
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                    <g transform="translate(1716.000000, 291.000000)">
                                                        <g transform="translate(4.000000, 301.000000)">
                                                            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="icon icon-md">
                                        <svg width="45px" height="45px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <title>email</title>
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g transform="translate(-2319.000000, -440.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                    <g transform="translate(1716.000000, 291.000000)">
                                                        <g id="time-alarm" transform="translate(603.000000, 149.000000)">
                                                            <defs><clipPath id="id1"><path d="M 3.460938 6.5625 L 26.539062 6.5625 L 26.539062 24.707031 L 3.460938 24.707031 Z M 3.460938 6.5625 " clip-rule="nonzero"/></clipPath></defs>
                                                            <g clip-path="url(#id1)"><path fill="923DFA" d="M 24.230469 11.101562 L 15 16.769531 L 5.769531 11.101562 L 5.769531 8.832031 L 15 14.503906 L 24.230469 8.832031 Z M 24.230469 6.5625 L 5.769531 6.5625 C 4.492188 6.5625 3.472656 7.578125 3.472656 8.832031 L 3.460938 22.441406 C 3.460938 23.695312 4.492188 24.707031 5.769531 24.707031 L 24.230469 24.707031 C 25.507812 24.707031 26.539062 23.695312 26.539062 22.441406 L 26.539062 8.832031 C 26.539062 7.578125 25.507812 6.5625 24.230469 6.5625 " fill-opacity="1" fill-rule="nonzero"/></g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                  </div>
                                  <h3 class="text-white h5">대체문자발송</h3>
                                  <p class="text-white opacity-8">다양한 채널을 한번에 보내세요. 메시지전송 실패하면 다른 채널로 전송합니다.</p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="info">
                                  <div class="icon icon-md">
                                      <svg width="25px" height="25px" viewBox="0 0 42 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                          <title>time-alarm</title>
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <g transform="translate(-2319.000000, -440.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                  <g transform="translate(1716.000000, 291.000000)">
                                                      <g id="time-alarm" transform="translate(603.000000, 149.000000)">
                                                          <path d="M18.8086957,4.70034783 C15.3814926,0.343541521 9.0713063,-0.410050841 4.7145,3.01715217 C0.357693695,6.44435519 -0.395898667,12.7545415 3.03130435,17.1113478 C5.53738466,10.3360568 11.6337901,5.54042955 18.8086957,4.70034783 L18.8086957,4.70034783 Z" opacity="0.6"></path>
                                                          <path d="M38.9686957,17.1113478 C42.3958987,12.7545415 41.6423063,6.44435519 37.2855,3.01715217 C32.9286937,-0.410050841 26.6185074,0.343541521 23.1913043,4.70034783 C30.3662099,5.54042955 36.4626153,10.3360568 38.9686957,17.1113478 Z" opacity="0.6"></path>
                                                          <path d="M34.3815652,34.7668696 C40.2057958,27.7073059 39.5440671,17.3375603 32.869743,11.0755718 C26.1954189,4.81358341 15.8045811,4.81358341 9.13025701,11.0755718 C2.45593289,17.3375603 1.79420418,27.7073059 7.61843478,34.7668696 L3.9753913,40.0506522 C3.58549114,40.5871271 3.51710058,41.2928217 3.79673036,41.8941824 C4.07636014,42.4955431 4.66004722,42.8980248 5.32153275,42.9456105 C5.98301828,42.9931963 6.61830436,42.6784048 6.98113043,42.1232609 L10.2744783,37.3434783 C16.5555112,42.3298213 25.4444888,42.3298213 31.7255217,37.3434783 L35.0188696,42.1196087 C35.6014207,42.9211577 36.7169135,43.1118605 37.53266,42.5493622 C38.3484064,41.9868639 38.5667083,40.8764423 38.0246087,40.047 L34.3815652,34.7668696 Z M30.1304348,25.5652174 L21,25.5652174 C20.49574,25.5652174 20.0869565,25.1564339 20.0869565,24.6521739 L20.0869565,15.5217391 C20.0869565,15.0174791 20.49574,14.6086957 21,14.6086957 C21.50426,14.6086957 21.9130435,15.0174791 21.9130435,15.5217391 L21.9130435,23.7391304 L30.1304348,23.7391304 C30.6346948,23.7391304 31.0434783,24.1479139 31.0434783,24.6521739 C31.0434783,25.1564339 30.6346948,25.5652174 30.1304348,25.5652174 Z"></path>
                                                      </g>
                                                  </g>
                                              </g>
                                          </g>
                                      </svg>
                                  </div>
                                  <p class="text-white h5">열람시간 확인</p>
                                  <p class="text-white opacity-8">문자에 링크를 누가 언제 몇번 눌렀는지 확인 가능합니다.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4 ms-auto z-index-1">
                      <div class="card mb-lg-5">
                          <img class="card-img-top" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/mic-check.jpg" alt="Mic Image">
                          <div class="position-relative overflow-hidden" style="height:50px;margin-top:-50px;">
                              <div class="position-absolute w-100 top-0 z-index-1">
                                  <svg class="waves waves-sm" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                                      <defs>
                                          <path id="card-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                                      </defs>
                                      <g class="moving-waves">
                                          <use xlink:href="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30"></use>
                                          <use xlink:href="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                          <use xlink:href="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                          <use xlink:href="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                          <use xlink:href="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                          <use xlink:href="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)"></use>
                                      </g>
                                  </svg>
                              </div>
                          </div>
                          <div class="card-body">
                              <p class="h4">다양한 메시지 기능</p>
                              <p>단순한 메시지전송 솔루션? No!<br> 
                                 다양한 메시징 기능을 직접 체험해 보세요.
                              </p>
                              <a href="/authentication/signup/auth" class="text-primary icon-move-right">가입 후 체험하기&nbsp;<i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="position-absolute w-100 bottom-0">
              <svg width="100%" viewBox="0 -1 1920 166" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>wave-up</title>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(0.000000, 5.000000)" fill="#FFFFFF" fill-rule="nonzero">
                          <g id="wave-up" transform="translate(0.000000, -5.000000)">
                              <path d="M0,70 C298.666667,105.333333 618.666667,95 960,39 C1301.33333,-17 1621.33333,-11.3333333 1920,56 L1920,165 L0,165 L0,70 Z"></path>
                          </g>
                      </g>
                  </g>
              </svg>
          </div>
      </section>
  
      <section class="py-5">
          <div class="container">
              <div class="row text-center">
                  <div class="col-lg-3 col-md-6">
                      <div class="info">
                          <div class="icon icon-shape mx-auto">
                              <svg class="text-info" width="25px" height="25px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <title>box-3d-50</title>
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <g transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                          <g transform="translate(1716.000000, 291.000000)">
                                              <g id="box-3d-50" transform="translate(603.000000, 0.000000)">
                                                  <path class="color-foreground" d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"></path>
                                                  <path class="color-background" d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z" opacity="0.7"></path>
                                                  <path class="color-background" d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z" opacity="0.7"></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <p class="mt-2 h5">무중단 아키텍처</p>
                          <p>긴급문자, 인증문자 등 중요한 문자가 메시징솔루션때문에 발송실패 경우가 있으면 안됩니다. 저희 아키텍처는 이중화 및 컴포넌트 단위 설계로 멈추는 일이 없습니다.</p>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                      <div class="info">
                          <div class="icon icon-shape mx-auto">
                              <svg class="text-info" width="25px" height="25px" viewBox="0 0 46 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <title>customer-support</title>
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <g transform="translate(-1717.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                          <g transform="translate(1716.000000, 291.000000)">
                                              <g transform="translate(1.000000, 0.000000)">
                                                  <path class="color-background" d="M45,0 L26,0 C25.447,0 25,0.447 25,1 L25,20 C25,20.379 25.214,20.725 25.553,20.895 C25.694,20.965 25.848,21 26,21 C26.212,21 26.424,20.933 26.6,20.8 L34.333,15 L45,15 C45.553,15 46,14.553 46,14 L46,1 C46,0.447 45.553,0 45,0 Z" opacity="0.59858631"></path>
                                                  <path class="color-foreground" d="M22.883,32.86 C20.761,32.012 17.324,31 13,31 C8.676,31 5.239,32.012 3.116,32.86 C1.224,33.619 0,35.438 0,37.494 L0,41 C0,41.553 0.447,42 1,42 L25,42 C25.553,42 26,41.553 26,41 L26,37.494 C26,35.438 24.776,33.619 22.883,32.86 Z"></path>
                                                  <path class="color-foreground" d="M13,28 C17.432,28 21,22.529 21,18 C21,13.589 17.411,10 13,10 C8.589,10 5,13.589 5,18 C5,22.529 8.568,28 13,28 Z"></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <p class="mt-2 h5">사용자 친화적 UI/UX</p>
                          <p>기존 솔루션을 사용하기 불편하신가요? 원하시는 Dashboard가 안나오나요?&nbsp;<span style="font-size: 1rem; color: var(--bs-body-color); font-family: var(--bs-body-font-family);">사용자 입장에서 설계 및 디자인하여 쉽고 직관적인 웹 화면을 제공합니다.</span></p>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                      <div class="info">
                          <div class="icon icon-shape mx-auto">
                              <svg class="text-info" width="25px" height="25px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <title>spaceship</title>
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                          <g transform="translate(1716.000000, 291.000000)">
                                              <g transform="translate(4.000000, 301.000000)">
                                                  <path class="color-foreground" d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                                  <path class="color-foreground" d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                                  <path class="color-background" d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" opacity="0.598539807"></path>
                                                  <path id="color-3" class="color-background" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" opacity="0.598539807"></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <p class="mt-2 h5">성능</p>
                          <p>대량문자발송을 하는데 계속 기다리셔야 하나요? 서버리스 아키텍처를 통해 무한 Scaling이 가능한 메시징 솔루션을 만나보세요.</p>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                      <div class="info">
                          <div class="icon icon-shape mx-auto">
                              <svg width="90px" height="90px" viewBox="0 0 42 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <title>dollar</title>
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <g transform="translate(-2319.000000, -440.000000)" fill="black" fill-rule="nonzero">
                                          <g transform="translate(1716.000000, 291.000000)">
                                              <g id="time-alarm" transform="translate(603.000000, 149.000000)">
                                                  <path fill="#2152ff" d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <p class="mt-2 h5">가격</p>
                          <p>저렴하게 문자전송을 해보세요. 너무 가격이 저렴해서 놀라지 마시고요. SMS, LMS, MMS, 알림톡, 이메일, 앱푸시 등 다양한 채널을 최저가에 전송하세요.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      
      <section class="py-3">
      <!-- <div id="product" class="bg-gradient-primary position-relative m-3 border-radius-xl"> -->
      <div id="product">
        <!-- <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/shapes/waves-white.svg" alt="pattern-lines" class="position-absolute opacity-6 start-0 top-0 w-100"> -->
        <div class="container pb-lg-5 pb-10 pt-7 postion-relative z-index-2">
          <div class="row">
            <div class="col-md-6 mx-auto text-center">
              <p class="text-gradient text-primary mb-0 mb-2 h2">요금 안내</p>
              <p class="text-dark text-lg mt-3">(VAT 별도)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-3">
            <span class="badge bg-gradient-danger mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto h-100 w-100 d-flex align-items-center justify-content-center" style="font-size: 24px;">
              <!-- <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/shapes/waves-white.svg" alt="pattern-lines" class="position-absolute opacity-6 start-0 top-0 w-100"> -->
              문자전송
            </span>
          </div>
          <div class="col-md-3">
            <p class="text-gradient text-danger h2">협의</p>
            <h4 class="mt-3 h5">SMS</h4>
          </div>
          <div class="col-md-3">
            <p class="text-gradient text-danger h2">협의</p>
            <h4 class="mt-3 h5">LMS</h4>
          </div>
          <div class="col-md-3">
            <p class="text-gradient text-danger h2">협의</p>
            <h4 class="mt-3 h5">MMS</h4>
          </div>
        </div>
      </div>
    </section>
    <!-- primary, secondary, success, info, warning, danger, light, dark, white, faded-primary... -->
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-3">
            <span class="badge bg-gradient-warning mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto h-100 w-100 d-flex align-items-center justify-content-center" style="font-size: 24px;">알림톡전송</span>
          </div>
          <div class="col-md-5">
            <p class="text-gradient text-warning h2">협의</p>
            <h4 class="mt-3 h5">알림톡(TEXT)</h4>
          </div>
          <div class="col-md-4">
            <p class="text-gradient text-warning h2">협의</p>
            <h4 class="mt-3 h5">알림톡(이미지)</h4>
          </div>
          <!-- <div class="col-md-3">
            <h1 class="text-gradient text-warning">TBD</h1>
            <p class="mt-3">친구톡(TEXT)</p>
          </div> -->
        </div>
        
      </div>
    </section>  
    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-3">
            <span class="badge bg-gradient-info mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto h-100 w-100 d-flex align-items-center justify-content-center" style="font-size: 24px;">메일전송</span>
          </div>
          <div class="col-md-5">
            <p class="text-gradient text-info h2">협의</p>
            <h3 class="mt-3 h5">이메일</h3>
          </div>
          <div class="col-md-4">
            <p class="text-gradient text-info h2">협의</p>
            <h3 class="mt-3 h5">팩스</h3>
          </div>
          <!-- <div class="col-md-3">
            <h1 class="text-gradient text-info">TBD</h1>
            <p class="mt-3">푸시</p>
          </div> -->
        </div>
      </div>
    </section>
      <section class="py-4">
          <div class="container">
              <div class="row my-5">
                  <div class="col-md-6 mx-auto text-center">
                      <p class="h2">자주 묻는 질문</p>
                      <p>추가 문의 사항은 이메일 또는 전화를 통해 답변 드리겠습니다.</p>
                  </div>
              </div>
              <div class="row">
                  <div>
                      <div id="accordionRental" class="accordion">
                          <div class="accordion">
                            <div class="accordion-header" data-target="#accordion-body1" @click="toggleAccordion($event.currentTarget)">
                                <h5 class="h5">SK(주) C&C 대량문자발송 메시징 솔루션, 메시지투고를 어떻게 사용 할 수 있나요?</h5>
                                <i id="accordion-icon" class="icon">+</i>
                            </div>
                            <div id="accordion-body1" class="accordion-body" style="display: none;">
                                회원가입하면 바로 사용 가능합니다. 저희 화면을 통해 문자전송 할 수 있으며 Postman 또는 사용하시는 환경에서 API 방식으로도 문자전송이 가능합니다.<br>
                                웹에서 SMS, LMS, MMS 및 카카오 알림톡 전송이 가능하며 대량전송, 예약전송 및 주소록전송 등 다양한 기능이 제공 됩니다.<br>
                                파라미터 처리를 통해 주소록/대량문자전송도 수신자 별 문자 Customizing이 가능하며 문자발송에 대한 결과를 알림톡으로 보낸 담당자에게 전송도 가능합니다.<br>
                                웹 화면 외 API, DB Agent로도 문자전송이 가능하며 성능역시 뛰어납니다. API를 통해 친구톡, 이메일, 팩스, Slack, Teams 등도 전송 가능합니다.
                            </div>
                          </div>
                          <div class="accordion">
                            <div class="accordion-header" data-target="#accordion-body2" @click="toggleAccordion($event.currentTarget)">
                                <h5 class="h5">메시징사용료 청구 및 결제는 어떻게 할 수 있나요?</h5>
                                <i id="accordion-icon" class="icon">+</i>
                            </div>
                            <div id="accordion-body2" class="accordion-body" style="display: none;">
                                가입하신 사업자 및 회원정보로 세금계산서 및 청구서가 이메일로 전달 됩니다.
                            </div>
                          </div>
                          <div class="accordion">
                            <div class="accordion-header" data-target="#accordion-body3" @click="toggleAccordion($event.currentTarget)">
                                <h5 class="h5">저 말고 다른 사용자도 등록하여 사용 할 수 있나요?</h5>
                                <i id="accordion-icon" class="icon">+</i>
                            </div>
                            <div id="accordion-body3" class="accordion-body" style="display: none;">
                                네! 관리자 화면에서 사용자 관리를 통해 사용자를 추가하실 수 있습니다.<br>
                                조직관리를 원하시면 부서도 생성 가능하며 생성된 부서로 사용자를 이동시키면 됩니다.<br>
                                부서별 문자전송이력 및 비용관리가 가능합니다.
                            </div>
                          </div>
                          <div class="accordion">
                            <div class="accordion-header" data-target="#accordion-body4" @click="toggleAccordion($event.currentTarget)">
                                <h5 class="h5">실시간으로 전송결과 및 이달 문자발송 비용을 확인 할 수 있나요?</h5>
                                <i id="accordion-icon" class="icon">+</i>
                            </div>
                            <div id="accordion-body4" class="accordion-body" style="display: none;">
                                관리자 화면을 통해 현재까지 문자 사용량 및 문자전송 성공/실패 건수를 바로 확인 할 수 있습니다.<br>
                                보낸 담당자가 전송결과를 알림톡으로 수신요청 할 수 있으며 대량발송 후 전체건수, 성공건수, 실패건수를 5분 후 회신드립니다.
                            </div>
                          </div>
                          <div class="accordion">
                            <div class="accordion-header" data-target="#accordion-body5" @click="toggleAccordion($event.currentTarget)">
                                <h5 class="h5">문자메시지 규격은 어떻게 되나요?</h5>
                                <i id="accordion-icon" class="icon">+</i>
                            </div>
                            <div id="accordion-body5" class="accordion-body" style="display: none;">
                                일반적인 SMS 문자의 경우 최대 90 byte까지 보낼 수 있습니다. 한글 기준 45자리, 영문 90자리 입니다.<br>
                                LMS와 MMS의 경우 내용은 최대 2000 byte까지 보낼 수 있으며 이미지는 3개까지 첨부 가능하며 해상도는 통신사별로 조금 씩 다르지만 1000x1000 미만으로 보내시면 되겠습니다.<br>
                                이미지는 JPG만 지원되며 최대 300KB 파일사이즈까지 지원 됩니다.
                            </div>
                          </div>
                          <div class="accordion">
                            <div class="accordion-header" data-target="#accordion-body6" @click="toggleAccordion($event.currentTarget)">
                                <h5 class="h5">어떻게 이렇게 저렴한 가격에 문자 서비스를 제공 할 수 있나요?</h5>
                                <i id="accordion-icon" class="icon">+</i>
                            </div>
                            <div id="accordion-body6" class="accordion-body" style="display: none;">
                                저희는 경쟁사 대비 인프라 운영 및 운영인력 비용을 획기적으로 낮췄습니다. 뛰어난 아키텍처로 비용은 줄였지만 문자전송 성능 및 안정성은 확보 할 수 있었습니다.<br>
                                저희가 아낀 비용은 그대로 고객에게 전달해 드릴 수 있었습니다.
                            </div>
                          </div>
                          <div class="accordion">
                            <div class="accordion-header" data-target="#accordion-body7" @click="toggleAccordion($event.currentTarget)">
                                <h5 class="h5">선거문자나 광고문자를 대량으로 발송하고 싶은데 어떻게 하나요?</h5>
                                <i id="accordion-icon" class="icon">+</i>
                            </div>
                            <div id="accordion-body7" class="accordion-body" style="display: none;">
                                광고문자의 경우 문자 시작부분에 (광고) 를 표시해야 하며 수신자가 광고를 인지할 수 있도록 전송자의 명칭과 전화번호 또는 주소가 표시되야 합니다.<br>
                                LMS, MMS의 경우 (광고)가 제목에도 있어야 합니다.<br>
                                추가적으로 수신거부 방법이 무료로 제공되어야 합니다. Message To-Go는 080 무료수신거부 번호를 모든 고객에 무료로 제공하고 있습니다.<br>
                                웹에서 대량발송을 하기 위해선 주소록 엑셀파일에 수신자번호를 넣고 업로드하면 약 5만건까지 일괄 전송 가능합니다.<br>
                                API로 대량전송을 하기 위해선 Payload 내 수신자번호 1000건씩 포함시켜 전송 가능합니다.<br>
                                DB Agent로 대량전송을 하려면 DB에 수신자번호를 Insert하면 최대 1000TPS로 문자전송이 진행 됩니다. (DB 서버 Spec 및 Agent 프로세스 수에 따라 상이)
                            </div>
                          </div>
                          <div class="accordion">
                            <div class="accordion-header" data-target="#accordion-body8" @click="toggleAccordion($event.currentTarget)">
                                <h5 class="h5">무료로 080 수신거부 전화번호도 제공하나요? 전용 080수신거부번호도 부여 가능한가요? 어떻게 사용하나요?</h5>
                                <i id="accordion-icon" class="icon">+</i>
                            </div>
                            <div id="accordion-body8" class="accordion-body" style="display: none;">
                                메시지투고 고객은 모두 무료로 080 수신거부서비스를 받으실 수 있습니다.<br>
                                화면에서 문자전송을 하실 때 080수신거부 체크를 하시면 자동으로 수신자가 무료로 전화 할 수 있는 080번호와 전화시 입력할 상점코드가 표기 됩니다.<br>
                                수신거부된 번호는 문자발송 요청이 있어도 발송이 안되며 실패사유로 수신거부번호로 표기 됩니다.<br>
                                API 및 DP Agent의 경우 저희가 제공하는 080수신거부번호와 상점코드를 문자에 입력해서 전송하시면 됩니다.<br>
                                상점코드 입력이 필요없는 전용 080번호를 부여받고 싶으시면 저렴한 가격에 안내드리고 있으니 이메일 또는 문의하기로 문의 부탁드립니다.
                            </div>
                          </div>
                          <div class="accordion">
                            <div class="accordion-header" data-target="#accordion-body9" @click="toggleAccordion($event.currentTarget)">
                                <h5 class="h5">불법스팸 또는 불법광고는 차단되나요?</h5>
                                <i id="accordion-icon" class="icon">+</i>
                            </div>
                            <div id="accordion-body9" class="accordion-body" style="display: none;">
                                알림톡 등을 이용한 불법스팸 및 불법광고는 메시지투고만의 로직을 통해 자동 차단되고 있습니다.<br>
                                메시지투고는 문자발송 또는 알림톡발송을 대량으로 보낼 경우 시스템이 내용을 자동으로 파악하여 의심되는 메시지는 우선적으로 차단합니다. <br>
                                어뷰징이 아닌데 차단되었다면 고객센터에 문의하여 문자내용 수정 등을 통해 차단이 안되도록 도와드리고 있습니다.<br>
                                메시지투고는 불법스팸 및 불법광고에 대해 강경한 대응을 하고 있으며 KISA 및 유관부서와 협의하여 지속적으로 대응체계 및 대응방안을 개선해 나가고 있습니다.<br>
                                저희가 어떠한 방식으로 불법 대량문자를 차단하는지 알고 싶으시면 고객센터로 문의 부탁드립니다.
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="py-5 position-relative">
          <div class="page-header min-vh-50 m-3 border-radius-xl" style="background-image: url('https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/curved-images/curved8.jpg');">
              <span class="mask bg-gradient-dark opacity-4"></span>
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-lg-8 text-center mx-auto">
                          <p class="text-white mt-4 mb-1 h2">Message를 To-Go하다!</p>
                          <p class="lead text-white mb-6">심플하고 빠른 통합 메시징 솔루션</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="container">
              <div class="row mt-n7 blur border-radius-lg shadow-blur">
                  <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
                      <div class="p-3 text-center">
                          <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                              <svg class="mt-3" width="15px" height="15px" viewBox="0 0 45 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <title>map-big</title>
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <g transform="translate(-2321.000000, -593.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                          <g transform="translate(1716.000000, 291.000000)">
                                              <g id="map-big" transform="translate(605.000000, 302.000000)">
                                                  <polygon points="31.875 0.71625 24.375 4.46625 24.375 40.53375 31.875 36.78375"></polygon>
                                                  <polygon points="20.625 4.46625 13.125 0.71625 13.125 36.78375 20.625 40.53375"></polygon>
                                                  <path d="M9.375,0.81375 L0.909375,5.893125 C0.346875,6.230625 0,6.84 0,7.5 L0,43.125 L9.375,37.06125 L9.375,0.81375 Z" opacity="0.70186942"></path>
                                                  <path d="M44.090625,5.893125 L35.625,0.81375 L35.625,37.06125 L45,43.125 L45,7.5 C45,6.84 44.653125,6.230625 44.090625,5.893125 Z" opacity="0.70186942"></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <p class="mt-3 mb-1 h5">주소</p>
                          <p class="mb-0">경기도 성남시 분당구 <br> 성남대로343번길 9 (SK u타워)</p>
                          <hr class="vertical dark">
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
                      <div class="p-3 text-center">
                          <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                              <svg class="mt-3" width="15px" height="15px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <title>document</title>
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                          <g transform="translate(1716.000000, 291.000000)">
                                              <g transform="translate(154.000000, 300.000000)">
                                                  <path d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" opacity="0.603585379"></path>
                                                  <path d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <p class="mt-3 mb-1 h5">e-Mail</p>
                          <img src="https://to-go.io/mail.png" alt="mail" height="20" style="margin-top: 30px;">
                      </div>
                      <hr class="vertical dark">
                  </div>
                  <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
                      <div class="p-3 text-center">
                          <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                              <svg class="mt-3" width="15px" height="15px" viewBox="0 0 44 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <title>megaphone</title>
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <g transform="translate(-2168.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                          <g transform="translate(1716.000000, 291.000000)">
                                              <g id="megaphone" transform="translate(452.000000, 300.000000)">
                                                  <path d="M35.7958333,0.273166667 C35.2558424,-0.0603712374 34.5817509,-0.0908856664 34.0138333,0.1925 L19.734,7.33333333 L9.16666667,7.33333333 C4.10405646,7.33333333 0,11.4373898 0,16.5 C0,21.5626102 4.10405646,25.6666667 9.16666667,25.6666667 L19.734,25.6666667 L34.0138333,32.8166667 C34.5837412,33.1014624 35.2606401,33.0699651 35.8016385,32.7334768 C36.3426368,32.3969885 36.6701539,31.8037627 36.6666942,31.1666667 L36.6666942,1.83333333 C36.6666942,1.19744715 36.3370375,0.607006911 35.7958333,0.273166667 Z"></path>
                                                  <path d="M38.5,11 L38.5,22 C41.5375661,22 44,19.5375661 44,16.5 C44,13.4624339 41.5375661,11 38.5,11 Z" opacity="0.601050967"></path>
                                                  <path d="M18.5936667,29.3333333 L10.6571667,29.3333333 L14.9361667,39.864 C15.7423448,41.6604248 17.8234451,42.4993948 19.6501416,41.764381 C21.4768381,41.0293672 22.3968823,38.982817 21.7341667,37.1286667 L18.5936667,29.3333333 Z" opacity="0.601050967"></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <p class="mt-3 mb-1 h5">전화번호</p>
                          <p class="mb-0"><br>(02)6400-2613</p>
                      </div>
                      <hr class="vertical dark">
                  </div>
                  <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
                      <div class="p-3 text-center">
                          <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                              <svg class="mt-3" width="15px" height="15px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <title>ungroup</title>
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <g transform="translate(-2170.000000, -442.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                          <g transform="translate(1716.000000, 291.000000)">
                                              <g id="ungroup" transform="translate(454.000000, 151.000000)">
                                                  <path d="M38.1818182,10.9090909 L32.7272727,10.9090909 L32.7272727,30.9090909 C32.7272727,31.9127273 31.9127273,32.7272727 30.9090909,32.7272727 L10.9090909,32.7272727 L10.9090909,38.1818182 C10.9090909,39.1854545 11.7236364,40 12.7272727,40 L38.1818182,40 C39.1854545,40 40,39.1854545 40,38.1818182 L40,12.7272727 C40,11.7236364 39.1854545,10.9090909 38.1818182,10.9090909 Z" opacity="0.6"></path>
                                                  <path d="M27.2727273,29.0909091 L1.81818182,29.0909091 C0.812727273,29.0909091 0,28.2781818 0,27.2727273 L0,1.81818182 C0,0.814545455 0.812727273,0 1.81818182,0 L27.2727273,0 C28.2781818,0 29.0909091,0.814545455 29.0909091,1.81818182 L29.0909091,27.2727273 C29.0909091,28.2781818 28.2781818,29.0909091 27.2727273,29.0909091 Z"></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <p class="mt-3 mb-1 h5">영업 담당자</p>
                          <p class="mb-0"><br>오상준 매니저</p>
                      </div>
                  </div>
              </div>
              <div class="container">
                <br>
                <div class="container-fluid">
                  <div class="row align-items-center justify-content-lg-between">
                    <div class="mb-4 col-lg-9 mb-lg-0">
                      <div
                        class="text-sm"
                      > ©
                        2024, 
                        <!-- <i class="fa fa-heart"></i>  -->
                        by
                        <a
                          href="https://www.sk.com"
                          class="font-weight-bold"
                          target="_blank"
                          >SK 주식회사 C&C</a
                        >
                      </div>
                      <span style="font-size: 0.8rem; margin-right: 0.5rem;">
            SK(주) | 대표이사: 장용호, 최태원 | 경기도 성남시 분당구 성남대로 343번길 9 | 사업자등록번호 783-85-00169 | 통신판매업신고 2015-경기성남-1388 <br> COPYRIGHT SK HOLDINGS CO., LTD. ALL RIGHTS RESERVED. (ver.1.2.0)
            </span>
                    </div>
                    <div class="col-lg-2">
                      <ul
                        class="nav nav-footer"
                      >
                        <li class="nav-item" style="margin-bottom: -10px;">
                          <a
                            href="/서비스 이용 약관_SK(주)_Message To-Go.pdf"
                            class="nav-link text-muted font-weight-bold"
                            target="_blank"
                            style="font-size: 0.8rem;"
                            >이용약관</a
                          >
                        </li>
                        <li class="nav-item" style="margin-bottom: -10px;">
                          <a
                            href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
                            class="nav-link text-muted font-weight-bold"
                            target="_blank"
                            style="font-size: 0.8rem;"
                            >서비스수준(SLA)</a
                          >
                        </li>
                        <li class="nav-item" style="margin-bottom: -10px;">
                          <a
                            href="/home/privacy"
                            class="nav-link pe-0 text-muted font-weight-bold"
                            target="_blank"
                            style="font-size: 1rem;"
                            >개인정보처리방침</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
  
          </div>
      </section>
   
    </body>  
</template>  

  <script>
  import { useStore } from 'vuex';
  export default {
    name: 'HomePage', 
  //   mounted() {
  //     this.$router.beforeEach((to, from, next) => {
  //     if (to.path !== from.path) {
  //       window.location.reload();
  //     } else {
  //       next();
  //     }
  //   });
  //   },
    data() {
      return {
        isUnderlined: false,
        selectedTab: 0,
      };
    },
    beforeMount() {
      const store = useStore();
      store.commit('toggleEveryDisplay', false);
    },
    beforeUnmount() {
      const store = useStore();
      store.commit('toggleEveryDisplay', true);
    },
    methods: {
      selectTab(tabId) {
        const tab = document.getElementById(tabId);
        if (tab) {
          const tabs = document.querySelectorAll('.nav-link');
          tabs.forEach((t) => {
            t.classList.remove('selected');
          });
          tab.classList.add('selected');
        }
      },
      getQueryString() {
        return window.location.search;
      },
      toggleAccordion(header) {
        var targetId = header.getAttribute('data-target');
        var body = document.querySelector(targetId);
        var icon = header.querySelector('.icon');
        if (body.style.display === "none") {
            body.style.display = "block";
            icon.textContent = "-";
        } else {
            body.style.display = "none";
            icon.textContent = "+";
        }
      },
    },
  };

</script>

  
  <style>
    .hover-underline:hover {
        text-decoration: underline !important;
    }

    .accordion-header {
        cursor: pointer;
        padding: 10px;
        /* border: 1px solid #ccc;
        border-radius: 5px; */
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .accordion-body {
        /* border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 5px 5px; */
        padding: 10px;
        display: none; /* Initially hidden */
    }

    .selected {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .large-button {
        font-size: 50px;
        padding: 10px 20px;
    }

    .icons-row {
        display: flex;
        align-items: center; /* This centers the icons vertically within the container */
    }

    .icon-md {
        display: flex; /* Apply flex to align SVG icon properly */
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
        width: 50px; /* Set width */
        height: 50px; /* Set height */
        /* Add more styling here as needed */
    }
    
  </style>
